<style global>
  :global(input),
  :global(button),
  :global(select),
  :global(textarea) {
    font-family: inherit;
    font-size: inherit;
    -webkit-padding: unset;
    padding: unset;
    margin: unset;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: unset;
  }

  /* Main */

  :global(body) {
    position: relative;
    /* padding: 8px 24px; */
  }

  @media (min-width: 415px) {
    :global(body) {
      margin: auto;
      padding: unset;
      /* border: solid 0.75px rgba(51, 51, 51, 0.15); */
    }

    :global(main) {
      width: 360px;
      height: 640px;
      /* overflow: auto; */
      margin: auto;
      position: absolute;
      top: -55px;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;
    }

    :global(test) {
      width: 360px;
      height: 640px;
      /* overflow: auto; */
      margin: auto;
      position: absolute;
      top: -55px;
      left: 0;
      bottom: 0;
      right: 0;
    }

    :global(result) {
      width: 360px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  :global(.size_14) {
    font-size: 14px;
  }

  :global(.header) {
    position: fixed;
    justify-content: space-between;
  }

  :global(.sub_title) {
    opacity: 0.6;
    font-family: NanumBarunGothic;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #333333;

    padding-top: 16px;
    /* padding-left: 24px; */
  }

  :global(.left) {
    text-align: left;
    padding-left: 8px;
  }

  @media (min-width: 415px) {
    :global(.left) {
      padding: 16px 183px 0 24px;
    }
  }

  :global(.right) {
    display: inline-block;
    position: fixed;
    width: 42px;
    height: 16px;
    text-align: right;
    right: 0;
    padding-top: 16px;
    padding-right: 24px;
  }

  @media (min-width: 415px) {
    :global(.right) {
      position: static;
      /* position: relative; */
      /* padding-right: 183px; */
    }
  }

  :global(main) :global(.img_box) {
    display: block;
    /* width: 240px; */
    height: 180px;
    padding: 72px 0 63px;
    text-align: center;
  }

  :global(main) :global(input) {
    padding: 6.4px 26px;
    font-family: NanumBarunGothic;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(51, 51, 51, 0.5);
    caret-color: #632ed8;
  }

  :global(main) :global(input::placeholder) {
    padding-left: 4px;
    opacity: 0.6;
  }

  :global(.form_box) {
    text-align: center;
  }

  :global(.form_box) :global(.box_rounded) {
    margin: auto;
  }

  :global(input:focus),
  :global(textarea:focus),
  :global(select:focus) {
    outline: none;
  }

  :global(.box_rounded),
  :global(.box_rounded) :global(button) {
    width: 256px;
    height: 50px;
    border-radius: 32px;
  }

  :global(.box_rounded.default) {
    margin-bottom: 16px;
    border: solid 0.75px rgba(51, 51, 51, 0.3);
    border-radius: 32px;
    background-color: #ffffff;
  }

  :global(.box_rounded.fill) :global(button) {
    border: none;
    border-radius: 25px;
    background-color: #632ed8;
  }

  :global(main) :global(.box_rounded) :global(button) {
    cursor: pointer;
    font-family: NanumBarunGothic;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  /* Test */

  :global(.flex.col) {
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
  }

  :global(test) :global(.btns) {
    padding-top: 48px;
  }

  :global(.btn) :global(button) {
    font-family: NanumBarunGothic;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
  }

  :global(test) :global(.content) {
    text-align: center;
    width: 100%;
    padding-top: 100px;
  }

  :global(test) :global(.content) :global(button) :global(b) {
    font-weight: 600;
  }

  :global(test) :global(.content.padding_20) {
    padding-top: 20px;
  }

  @media (min-width: 415px) {
    :global(test) :global(.content) {
      /* padding-top: unset; */
    }

    :global(test) :global(.content.padding_20) {
      padding-top: unset;
    }
  }

  :global(test) :global(button) {
    cursor: pointer;
    padding: 0 23px;
    width: 264px;
    height: 80px;
  }

  :global(button.default) {
    border-radius: 45px;
    border: solid 0.75px rgba(51, 51, 51, 0.3);
    background-color: #ffffff;
  }

  :global(button.active) {
    border: none;
    border-radius: 45px;
    background-color: #632ed8;
    font-family: NanumBarunGothic;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  :global(test) :global(.text) {
    font-family: NanumBarunGothic;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;

    display: inline-block;
    width: 320px;
    height: 70px;
  }

  :global(test) :global(.text) :global(b) {
    color: #632ed8;
  }

  :global(.versus) {
    width: 100%;
    height: 23px;
    padding: 16px 0;
    font-family: NanumBarunGothic;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(51, 51, 51, 0.3);
  }

  :global(.loading) :global(.text) {
    padding-top: 80px;
  }

  /* Result */

  :global(result) {
    text-align: center;
    font-family: NanumBarunGothic;
    padding: 0px 24px;
  }

  :global(.text_box) {
    padding: 24px 40px;
    line-height: 1.5;
  }

  :global(.title) {
    font-family: NanumBarunGothic;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #632ed8;
  }

  :global(result) :global(.title) {
    padding: 24px 0 24px 0;
  }

  :global(.size_16) {
    font-size: 16px;
  }

  :global(.size_20) {
    font-size: 20px;
  }

  :global(.size_24) {
    font-size: 24px;
  }

  :global(.highlight) {
    width: 89px;
    height: 31px;
    padding: 3px 12px 1px 12px;
    margin-right: 3px;
    background-color: #632ed8;
  }

  :global(.font_purp) {
    color: #632ed8;
  }

  :global(.white) {
    color: #ffffff;
  }

  :global(result) :global(.img_box) {
    /* width: 312px; */
    height: 232px;
    border-radius: 10px;
    border: solid 1px rgba(51, 51, 51, 0.15);
    background-color: rgba(51, 51, 51, 0.06);
  }

  :global(result) :global(.text) {
    padding: 0 0 24px;
    width: 100%;
    max-height: 140px;
    font-family: NanumBarunGothic;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    line-height: 1.5;
    letter-spacing: normal;
    color: #333333;
  }

  :global(.divide_container) {
    height: 8px;
  }

  :global(.divider) {
    display: inline-block;
    width: 312px;
    height: 1px;
    margin: 0 auto;
    vertical-align: top;
    background-color: rgba(51, 51, 51, 0.15);
  }

  :global(.title_container) {
    display: flex;
    padding-bottom: 8px;
    justify-content: space-between;
  }

  :global(result) :global(button) {
    cursor: pointer;
    padding: 15px 37px;
  }

  :global(result) :global(.btns) :global(button) {
    padding: unset;
  }

  :global(result) :global(.btns) {
    display: flex;
    flex-direction: column;
  }

  :global(result) :global(.btns) :global(.btn) {
    width: 264px;
    height: 48px;
    width: 100%;
    padding-bottom: 16px;
  }

  :global(result) :global(button.default) {
    border-radius: 25px;
    border: solid 2px rgba(51, 51, 51, 0.15);
    background-color: #ffffff;

    font-family: NanumBarunGothic;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #632ed8;
  }

  :global(result) :global(button.active) {
    border-radius: 25px;
    background-color: #632ed8;

    font-family: NanumBarunGothic;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  :global(result) :global(.text_box) :global(b) {
    margin-right: 4px;
  }

  :global(result) :global(ul) {
    all: unset;
    list-style: none;
  }

  :global(.default_bar) {
    position: relative;
    flex-wrap: wrap;
    height: 8px;
    -moz-border-radius: 4.5px;
    -webkit-border-radius: 4.5px;
    border-radius: 4.5px;
    margin-bottom: 24px;
    background-color: rgba(51, 51, 51, 0.1);
  }

  :global(ul) > :global(li:last-child) :global(.default_bar) {
    margin-bottom: 8px;
  }

  :global(.default_bar) > :global(span) {
    display: block;
    margin: 0;
    height: 100%;
    -moz-border-radius: 4.5px;
    -webkit-border-radius: 4.5px;
    border-radius: 4.5px;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(to left, #b287ff -55%, #632ed8);
  }

  :global(.content_box) {
    /* width: 312px; */
    margin-bottom: 8px;
    border-radius: 10px;
    border: solid 1px rgba(51, 51, 51, 0.15);
    background-color: rgba(51, 51, 51, 0.06);
  }

  :global(.content_box) :global(.inner) {
    top: 62%;
    margin: auto;
    width: 100%;
    position: absolute;

    font-family: NanumBarunGothic;
    font-size: 17px;
    padding: 8px 0;
    padding-top: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: #444444;
  }

  :global(.content_box) :global(.inner) > :global(div) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 8px;
    padding-bottom: 16px;
  }

  :global(.content_box) :global(.inner) :global(.btn_rounded) {
    min-width: 74px;
    height: 26px;
    padding: 0 10px;
    line-height: 26px;
    border-radius: 15px;
    border: solid 1px rgba(51, 51, 51, 0.15);
    background-color: #ffffff;
    font-family: NanumBarunGothic;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(51, 51, 51, 0.5);
  }

  :global(.height_298) {
    height: 298px;
  }

  :global(.height_264) {
    height: 264px;
  }

  :global(.content_box) {
    position: relative;
  }

  :global(.content_box) :global(.bottom.white) {
    /* width: 312px; */
    width: 100%;
    height: 48px;
    border-radius: 0 0 10px 10px;
    background-color: #ffffff;

    font-family: NanumBarunGothic;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 48px;
    letter-spacing: normal;
    text-align: center;
    color: #333333;

    display: block;
    position: absolute;
    bottom: 0em;
    left: 0em;
  }

  @media (min-width: 313px) {
    :global(content_box) :global(.bottom.white) {
      width: 100%;
    }
  }

  :global(.mid_center) {
    pointer-events: none;
    position: relative;
    top: -30px;
  }

  :global(result) :global(button) :global(.mid_center) {
    width: 115px;
    height: 42px;
    padding: 12px 36px 11px 36px;
    border-radius: 21px;
    background-color: #632ed8;
  }

  :global(result) :global(.img_box) :global(.img) {
    background-size: cover;
  }

  :global(result) :global(.image) {
    position: relative;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 168px;
    width: 100%;
  }

  :global(result) :global(.top_rounded) {
    border-radius: 10px 10px 0 0;
  }

  :global(result) :global(img.padding) {
    height: 184px;
    width: unset;
    max-width: 264px;
    padding: 24px;
    /* margin: auto;
    height: 100%; */
  }

  :global(result) :global(.btns.share) {
    padding: 32px 32px 32px 32px;
  }

  :global(result) :global(.btns.share) :global(button) {
    display: block;
    margin: auto;
    height: 100%;

    padding: none;
    margin: none;
    border: none;
    background: none;
  }

  :global(result) :global(.btns.share) :global(span:nth-child(2)) :global(svg) {
    padding-left: 1px;
    padding-top: 1px;
  }

  :global(result) :global(.btns.share) :global(span:last-child) :global(svg) {
    padding-left: 1px;
    padding-top: 2px;
  }

  :global(.see_more.active) {
    width: 264px;
    height: 48px;
    border-radius: 25px;
    text-align: center;
    color: #ffffff;
    background-color: #632ed8;
  }

  :global(result) :global(button.default) {
    width: 264px;
    height: 48px;
    border-radius: 25px;
    border: solid 2px rgba(51, 51, 51, 0.15);
    background-color: #ffffff;
  }

  :global(.btns.share) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  :global(.btns.share) :global(span) {
    width: 38px;
    height: 38px;
    padding: unset;
    border-radius: 50%;
    background-color: rgba(51, 51, 51, 0.06);

    display: block;
  }

  :global(.gif) {
    padding: 137px 154px;
  }

  /* Footer */

  :global(.footer) {
    display: block;
    position: absolute;
    text-align: center;
    margin: auto;
    bottom: 0;
    width: 100%;
    text-align: center;
    -webkit-padding-after: 30px;
    padding-bottom: 20px;
  }

  :global(.result) :global(.footer) {
    position: relative;
  }

  @media (min-width: 415px) {
    :global(.footer) {
      padding-top: 56px;
      /* width: unset; */
    }

    :global(result) :global(.footer) {
      width: unset;
    }
  }

  :global(.sp) {
    background-image: url(/img/logo.png);
    -webkit-background-size: 144px 114px;
    background-size: 144px 114px;
  }

  :global(.logo) {
    display: inline-block;
    position: relative;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    width: 144px;
    margin: auto;
    height: 28px;
  }

  @media (min-width: 768px) {
    :global(result) :global(.footer) {
      display: inline-block;
      position: relative;
      padding: 55px 108px 64px 108px;
    }
  }</style>
